import { createContext } from "react";
import { initialState, MenuAction, MenuState } from ".";

export const MenuContext = createContext<{
  state: MenuState;
  dispatch: React.Dispatch<MenuAction>;
}>(
  // Dummy initial state
  { state: initialState, dispatch: () => ({}) }
);
