import { PrismicLink } from "src/atoms";
import { FC } from "react";
import { HeaderSection } from "../../types";

import SiteHeaderDropdown from "./components/HeaderDropdown";
import { HEADER_CTA_CLICKED } from "../../constants";

const SiteHeaderSection: FC<{
  data: HeaderSection;
  menuIndex: number;
}> = ({ data, menuIndex }) => {
  if (!data.primary) throw Error();

  /**
   * If the section title has a link, don't display anything on click.
   * Only render a link.
   */

  if (!data.columns) {
    return (
      <PrismicLink
        eventLabel={data.primary.title}
        eventName={HEADER_CTA_CLICKED}
        link={data.primary.link}
        lineHeight="short"
        param={data.primary.link_param}
      >
        {data.primary.title}
      </PrismicLink>
    );
  }

  return <SiteHeaderDropdown data={data} menuIndex={menuIndex} />;
};

export default SiteHeaderSection;
