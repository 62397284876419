import { Fragment } from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { ShiIcon, Spacer } from "src/atoms";
import { Link } from "src/atoms/PrismicLink";
import ShiButton from "src/atoms/ShiButton";
import {
  Container,
  IconButton,
  Flex,
  useDisclosure,
  HStack,
  Button,
  chakra
} from "@chakra-ui/react";

import useHeaderDropdownState, { MenuContext } from "./state";
import { useInView } from "react-intersection-observer";
import SiteHeaderSection from "./slices/HeaderSection";
import MobileDrawer from "./components/MobileDrawer";

import { groupSections } from "./utils";
import SiteBanner from "./components/SiteBanner";
import { onClickNewAccount } from "src/lib/event";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import { ClickInfo } from "src/lib/event/types";

const SiteNavbar = () => {
  const navbarQuery = useStaticQuery<Gatsby.SiteNavbarV2DataQuery>(graphql`
    query SiteNavbarV2Data {
      prismicSiteNavbarV2 {
        ...SiteNavbarV2
      }
    }
  `);

  const data = navbarQuery.prismicSiteNavbarV2?.data;

  if (!data) throw Error();

  const visitorContext = useVisitorContext();
  const location = useLocation();

  const { ref, inView: scrollTop } = useInView({
    initialInView: true,
    rootMargin: "0px",
  });

  const drawer = useDisclosure();
  const { state, dispatch } = useHeaderDropdownState();

  const banner: any = data.body?.find(
    (slice) => slice?.__typename === "PrismicSiteNavbarV2DataBodySiteBanner",
  );
  const headerSections = groupSections(data);

  return (
    <Fragment>
      {/* Used to know if the user has scrolled */}
      <div ref={ref} />

      <Container
        position="sticky"
        size="full"
        top={0}
        background="white"
        zIndex="navbar"
        id="site-navbar"
      >
        {<SiteBanner open={scrollTop} {...banner} />}

        <MobileDrawer
          isOpen={drawer.isOpen}
          onClose={drawer.onClose}
          data={data}
          headerSections={headerSections!}
        />

        <Container
          as="nav"
          size="block"
          display="flex"
          alignItems={{ base: "center", navbar: "flex-start"}}
          justifyContent="space-between"
          paddingY={{ base: "space-16", navbar: "space-8" }}
          paddingX={{ base: "space-16", navbar: "space-58", "2xl": 0 }}
          borderBottom={{ base: "1px solid", navbar: "none" }}
          borderColor="grey-0"
          gridGap={{ base: "space-16", navbar: "0" }}
        >
          <IconButton
            as={GatsbyLink}
            aria-label="Shine logo"
            variant="ghost"
            flexShrink="0"
            marginY="auto"
            paddingY="0"
            icon={
              <ShiIcon
                name="ShineLogo"
                width={{ base: "70px", navbar: "96px" }}
                height="auto"
              />
            }
            to="/"
            _hover={{
              backgroundColor: "initial",
            }}
          />

          <Spacer
            display={{ base: "block", navbar: "none" }}
            maxWidth="100%"
          />

          <Button
            as="a"
            href="https://app.shine.fr/register"
            display={{ base: "block", navbar: "none" }}
            variant="yellow"
            fontSize="font-13"
            lineHeight="20px"
            fontWeight="medium"
            color="grey-0"
            py="6px !important"
            px="space-16"
            pr="space-16"
          >
            {data.mobile_button}

            <chakra.span
              transition="transform .2s ease-out"
              marginLeft="space-8"
              className="element-cta-icon"
            >
              →
            </chakra.span>
          </Button>

          <IconButton
            display={{ base: "flex", navbar: "none" }}
            aria-label="menu"
            icon={<ShiIcon name="menu" w="20px" h="20px" />}
            onClick={drawer.onOpen}
            variant="icon"
            width="32px"
            height="32px"
            minW="unset" //override default style
            marginRight="-8px" //to align with right end
          />

          <Flex
            alignItems="center"
            flexGrow={1}
            display={{ base: "none", navbar: "flex" }}
          >
            <MenuContext.Provider value={{ state, dispatch }}>
              <HStack
                ml={{ base: "space-24", lg: "space-40", xl: "space-80"}}
                fontSize="font-15"
                spacing="0"
                flexGrow="1"
              >
                {headerSections?.map((slice, index) => {
                  return (
                    <SiteHeaderSection
                      key={index}
                      data={slice}
                      menuIndex={index}
                    />
                  );
                })}
              </HStack>
            </MenuContext.Provider>

            <Spacer />

            <Link
              fontWeight="medium"
              fontSize="font-15"
              variant="link-secondary"
              href="https://app.shine.fr/"
              whiteSpace="nowrap"
            >
              {data.login_label}
            </Link>

            <ShiButton
              onClick={(e) => {
                const clickInfo: ClickInfo = {
                  label: data.start_label,
                  platform: 'web-desktop',
                  block: 'site_navbar',
                  fromLocation: location
                };

                onClickNewAccount(e, visitorContext, clickInfo)
              }}
              variant="yellow"
              size="md"
              fontSize="font-15"
              marginLeft="space-24"
              flexShrink="0"
            >
              {data.start_label}
            </ShiButton>
          </Flex>
        </Container>
      </Container>
    </Fragment>
  );
};

export default SiteNavbar;
