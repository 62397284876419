import { Stack, Container, Collapse } from "@chakra-ui/react";
import { FC } from "react";
import { Element } from "@prismicio/react";
import Renderer from "src/lib/renderer";
import paragraph from "src/lib/renderer/elements/paragraph";
import { heading2 } from "src/lib/renderer/elements/headings";

const SiteBanner: FC<
  Gatsby.SiteNavbarSiteBannerFragment & { open: boolean }
> = ({ primary, open }) => {
  
  if (!primary) return null;

  return (
    <Container
      size="block"
      paddingY="0"
      sx={{
        ".chakra-collapse": {
          maxWidth: "unset",
        },
      }}
    >
      <Collapse animateOpacity in={open} unmountOnExit>
        <Stack
          align="center"
          bg="grey-4"
          borderRadius="lg"
          direction={{ base: "column", md: "row" }}
          justify="center"
          marginY={{ base: "space-20", md: "space-14" }}
          paddingX={{ base: "space-32", md: "space-40" }}
          paddingY={{ base: "space-24", md: "space-16" }}
          textAlign={{ base: "center" }}
          spacing={{ base: "space-8", md: "space-8" }}
          width="100%"
        >
          <Renderer
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    fontSize: "font-20",
                    maxWidth: "200px",
                    marginBottom: '0'
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    fontSize: "font-14",
                    sx: {
                      ".chakra-link": {
                        display: "block",
                        marginTop: "space-8",
                        fontSize: "font-14",
                      },
                      "@media (min-width: 60em)": {
                        maxWidth: "756px",
                        textAlign: "left",
                        ".chakra-link": {
                          display: "inline-block",
                          marginTop: "0",
                        },
                        ".chakra-badge": {
                          fontSize: "font-12",
                          paddingX: "space-12",
                          marginRight: "space-12",
                        },
                      },
                    },
                  },
                }),
            }}
            field={primary.content}
          />
        </Stack>
      </Collapse>
    </Container>
  );
};

export default SiteBanner;
