import { Box, BoxProps } from "@chakra-ui/react";
import { FC } from "react";
import SiteFooter from "src/components/SiteFooter";
import SiteNavbar from "src/components/SiteNavbar";
import { useLocation } from "@reach/router";
import { ShiIcon } from "src/atoms";

const PageLayout: FC<
  BoxProps & {
    footer?: Gatsby.Maybe<Gatsby.SiteFooterFragment>;
  }
> = ({ children, footer, ...props }) => {
  /**
   * Mobile apps display the privacy page as a WebView,
   * which is the reason why the GET param allows a minimal layout.
   */
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const hasMinLayout = searchParams.get("min-layout") === "true";
  const isPrivacyPage = pathname === '/privacy/fr/';
  const shouldMinimizeLayout = isPrivacyPage && hasMinLayout;
  const isFull = !shouldMinimizeLayout;

  return (
    <Box {...props}>
      {shouldMinimizeLayout
        ? <ShiIcon display="block" margin="0 auto" name="ShineLogo" boxSize="135px" />
        : <SiteNavbar />
      }

      {children}

      <SiteFooter isFull={isFull} override={footer} />
    </Box>
  );
};

export default PageLayout;
